import kd from 'kd-tree-javascript';
import { createCanvas } from 'canvas';
import { sum } from 'lodash';

import { transformMetersToPixels } from './floorplan';
import colors from './randomColor';

const componentToHex = (c) => {
  const hex = (c || 0).toString(16);

  return hex.length === 1 ? `0${hex}` : hex;
};

export const scaleColors = [
  '#000066',
  '#0000FF', // blue
  '#00FFFF', // cyan
  '#00FF00', // lime
  '#FFFF00', // yellow
  '#FFA500', // orange
  '#FF0000', // red
  '#800000', // Maroon
  '#660066',
  '#990099',
  '#ff66ff',
];

export const singleScaleColors = [
  '#ffffff',
  '#ffff7f',
  '#ffff00',
  '#ffaa00',
  '#ff5500',
  '#ff0000',
  '#aa0000',
  '#550000',
];

const defaultGradient = {};
for (let i = 0; i < scaleColors.length; i += 1) {
  defaultGradient[parseFloat(i === 0 ? 0 : i / 10).toFixed(1)] = scaleColors[i];
}
const singleGradient = {};
for (let i = 0; i < singleScaleColors.length; i += 1) {
  singleGradient[parseFloat(i === 0 ? 0 : i / 7).toFixed(1)] =
    singleScaleColors[i];
}

export const gradient = (singleColor) => {
  // create a 1x256 gradient that we'll use to turn a grayscale heatmap into a colored one
  const gCanvas = createCanvas(1, 256);
  const gCtx = gCanvas.getContext('2d');
  const g = gCtx.createLinearGradient(0, 0, 0, 256);

  if (singleColor) {
    Object.keys(singleGradient).forEach((grValue) => {
      g.addColorStop(+grValue, singleGradient[grValue]);
    });
  } else {
    Object.keys(defaultGradient).forEach((grValue) => {
      g.addColorStop(+grValue, defaultGradient[grValue]);
    });
  }

  gCtx.fillStyle = g;
  gCtx.fillRect(0, 0, 1, 256);

  return gCtx.getImageData(0, 0, 1, 256).data;
};

export const getColor = (
  weight,
  minArg,
  maxArg,
  singleColor,
  useGradient,
  logarithmicScale
) => {
  if (!useGradient) {
    const interval = maxArg - minArg;
    const step = interval / 11;

    if (weight > step * 10) {
      return scaleColors[10];
    }

    if (weight > step * 9) {
      return scaleColors[9];
    }

    if (weight > step * 8) {
      return scaleColors[8];
    }

    if (weight > step * 7) {
      return scaleColors[7];
    }

    if (weight > step * 6) {
      return scaleColors[6];
    }

    if (weight > step * 5) {
      return scaleColors[5];
    }

    if (weight > step * 4) {
      return scaleColors[4];
    }

    if (weight > step * 3) {
      return scaleColors[3];
    }

    if (weight > step * 2) {
      return scaleColors[2];
    }

    if (weight > step * 1) {
      return scaleColors[1];
    }

    return scaleColors[0];
  }

  const value =
    (logarithmicScale === true
      ? Math.log10(1 + (9 * (weight - minArg)) / (maxArg - minArg))
      : (weight - minArg) / (maxArg - minArg)) * 255;
  const j = Math.round(value) * 4;
  const cGradient = gradient(singleColor);

  return `#${componentToHex(cGradient[j])}${componentToHex(
    cGradient[j + 1]
  )}${componentToHex(cGradient[j + 2])}`;
};

export const convertCanvasToBW = (untreatedImageData) => {
  const imageData = untreatedImageData;
  for (let i = 0; i < imageData.data.length; i += 4) {
    const r = imageData.data[i];
    const g = imageData.data[i + 1];
    const b = imageData.data[i + 2];

    // CIE luminance for the RGB
    const v = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    imageData.data[i + 0] = v; // Red
    imageData.data[i + 1] = v; // Green
    imageData.data[i + 2] = v; // Blue
    imageData.data[i + 3] = imageData.data[i + 3]; // Keep alpha
  }

  return imageData;
};

export const cell = (gridSize) => {
  const cCanvas = createCanvas(gridSize, gridSize);
  const cCtx = cCanvas.getContext('2d');

  cCtx.beginPath();
  cCtx.lineWidth = 0;
  cCtx.rect(0, 0, gridSize, gridSize);
  cCtx.fill();
  cCtx.closePath();

  return cCanvas;
};

export const distance = (a, b) =>
  Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2);

export const generateHeatMap = (heatData) =>
  new Promise((resolve) => {
    const data = heatData.data || [];

    const cellSize = heatData.cell_size || 0.5;
    const gridSize = cellSize / 10;
    const p = 1;
    const t = 1;
    const searchRadius = cellSize * t;

    // eslint-disable-next-line new-cap
    const tree = new kd.kdTree(data, distance, ['x', 'y']);

    const gridPoints = {};
    const generateGridForDataPoint = (d) => {
      const currentPoint = [
        Math.ceil((d.x - searchRadius) / gridSize) * gridSize + gridSize / 2,
        Math.ceil((d.y - searchRadius) / gridSize) * gridSize + gridSize / 2,
      ];

      while (
        currentPoint[0] <=
        Math.floor((d.x + searchRadius) / gridSize) * gridSize
      ) {
        while (
          currentPoint[1] <=
          Math.floor((d.y + searchRadius) / gridSize) * gridSize
        ) {
          if (
            gridPoints[
              `${parseFloat(currentPoint[0]).toFixed(4)}_${parseFloat(
                currentPoint[1]
              ).toFixed(4)}`
            ] === undefined
          ) {
            const point = { x: currentPoint[0], y: currentPoint[1] };
            let weight;

            const idwNeigbours = tree.nearest(point, 4, searchRadius ** 2);
            if (idwNeigbours.length > 0) {
              const idwNeigboursWeights = idwNeigbours.map((n) => {
                // n = [{ x: 0, y: 0, weight: 0 }, 0]
                const dist = n[1];
                const nPoint = n[0];

                return {
                  distance: 1 / dist ** p,
                  weight: nPoint.weight / dist ** p,
                };
              });

              weight =
                Math.round(
                  1000000 *
                    (sum(idwNeigboursWeights.map((w) => w.weight)) /
                      sum(idwNeigboursWeights.map((w) => w.distance)))
                ) / 1000000;
            }

            if (weight !== undefined) {
              gridPoints[
                `${parseFloat(currentPoint[0]).toFixed(4)}_${parseFloat(
                  currentPoint[1]
                ).toFixed(4)}`
              ] = {
                weight,
                x: currentPoint[0] - gridSize / 2,
                y: currentPoint[1] - gridSize / 2,
              };
            }
          }

          currentPoint[1] += gridSize;
        }

        currentPoint[0] += gridSize;
        currentPoint[1] =
          Math.ceil((d.y - searchRadius) / gridSize) * gridSize + gridSize / 2;
      }
    };

    const generateGrid = (position) => {
      if (position < data.length) {
        if (position % 10 === 0) {
          setTimeout(() => {
            generateGridForDataPoint(data[position]);
            generateGrid(position + 1);
          }, 0);
        } else {
          generateGridForDataPoint(data[position]);
          generateGrid(position + 1);
        }
      } else {
        const pointsArray = Object.values(gridPoints)
          .filter((point) => point.weight !== undefined)
          .map((point) => ({
            coordinates: [
              [point.x, point.y],
              [point.x + gridSize, point.y],
              [point.x + gridSize, point.y + gridSize],
              [point.x, point.y + gridSize],
            ],
            alpha:
              heatData.scale === 'logarithmic'
                ? Math.log10(
                    1 +
                      (9 * (point.weight - heatData.min_weight)) /
                        (heatData.max_weight - heatData.min_weight)
                  ) + 0.1
                : (point.weight - heatData.min_weight) /
                    (heatData.max_weight - heatData.min_weight) +
                  0.1,
          }));

        resolve(pointsArray);
      }
    };

    generateGrid(0);
  });

export const generateHeatMapCanvas = (
  width,
  height,
  gridData,
  transformationMatrix,
  scale
) =>
  new Promise((resolve) => {
    const cellSize = gridData.cell_size || 0.5;

    const transformedData = (gridData.data || []).map((d) => ({
      coordinates: transformMetersToPixels(
        [d.x, d.y],
        transformationMatrix,
        scale
      ),
      weight: d.weight,
    }));
    const gridSize = Math.round((scale * cellSize) / 5);

    const cGradient = gradient();
    const cCell = cell(gridSize);
    const opacity = 0.5;
    const canvas = createCanvas(width, height);
    const ctx = canvas.getContext('2d');
    const p = 1;
    const t = 1;
    const searchRadius = cellSize * scale * t;

    // Flip vertically (diff point of origin from ol and paper.js)
    ctx.translate(0, height);
    ctx.scale(1, -1);

    // eslint-disable-next-line new-cap
    const tree = new kd.kdTree(
      transformedData.map((d) => ({
        weight: d.weight,
        x: d.coordinates[0],
        y: d.coordinates[1],
      })),
      distance,
      ['x', 'y']
    );

    const gridPoints = {};
    const generateGridForDataPoint = (d) => {
      const currentPoint = [
        Math.ceil((d.coordinates[0] - searchRadius) / gridSize) * gridSize +
          gridSize / 2,
        Math.ceil((d.coordinates[1] - searchRadius) / gridSize) * gridSize +
          gridSize / 2,
      ];

      while (
        currentPoint[0] <=
        Math.floor((d.coordinates[0] + searchRadius) / gridSize) * gridSize
      ) {
        while (
          currentPoint[1] <=
          Math.floor((d.coordinates[1] + searchRadius) / gridSize) * gridSize
        ) {
          if (
            gridPoints[
              `${parseFloat(currentPoint[0]).toFixed(4)}_${parseFloat(
                currentPoint[1]
              ).toFixed(4)}`
            ] === undefined
          ) {
            const point = { x: currentPoint[0], y: currentPoint[1] };
            let weight;

            const idwNeigbours = tree.nearest(point, 4, searchRadius ** 2);
            if (idwNeigbours.length > 0) {
              const idwNeigboursWeights = idwNeigbours.map((n) => {
                // n = [{ x: 0, y: 0, weight: 0 }, 0]
                const dist = n[1];
                const nPoint = n[0];

                return {
                  distance: 1 / dist ** p,
                  weight: nPoint.weight / dist ** p,
                };
              });

              weight =
                Math.round(
                  1000000 *
                    (sum(idwNeigboursWeights.map((w) => w.weight)) /
                      sum(idwNeigboursWeights.map((w) => w.distance)))
                ) / 1000000;
            }

            if (weight !== undefined) {
              gridPoints[
                `${parseFloat(currentPoint[0]).toFixed(4)}_${parseFloat(
                  currentPoint[1]
                ).toFixed(4)}`
              ] = {
                weight,
                x: currentPoint[0] - gridSize / 2,
                y: currentPoint[1] - gridSize / 2,
              };
            }
          }

          currentPoint[1] += gridSize;
        }

        currentPoint[0] += gridSize;
        currentPoint[1] =
          Math.ceil((d.coordinates[1] - searchRadius) / gridSize) * gridSize +
          gridSize / 2;
      }
    };

    const generateGrid = (position) => {
      if (position < transformedData.length) {
        if (position % 10 === 0) {
          setTimeout(() => {
            generateGridForDataPoint(transformedData[position]);
            generateGrid(position + 1);
          }, 0);
        } else {
          generateGridForDataPoint(transformedData[position]);
          generateGrid(position + 1);
        }
      } else {
        const gridPointsArray = Object.values(gridPoints).filter(
          (gp) => gp.weight !== undefined
        );

        gridPointsArray.forEach((gridPoint) => {
          const alpha =
            gridData.scale === 'logarithmic'
              ? Math.log10(
                  1 +
                    (9 * (gridPoint.weight - gridData.min_weight)) /
                      (gridData.max_weight - gridData.min_weight)
                ) + 0.1
              : (gridPoint.weight - gridData.min_weight) /
                  (gridData.max_weight - gridData.min_weight) +
                0.1;
          ctx.globalAlpha = alpha > 1 ? 1 : alpha;
          ctx.drawImage(cCell, gridPoint.x, gridPoint.y, gridSize, gridSize);
        });

        const colored = ctx.getImageData(0, 0, width, height);
        for (let i = 0, len = colored.data.length, j; i < len; i += 4) {
          j = colored.data[i + 3] * 4;

          colored.data[i] = cGradient[j];
          colored.data[i + 1] = cGradient[j + 1];
          colored.data[i + 2] = cGradient[j + 2];
          colored.data[i + 3] = j === 0 ? 0 : opacity * 256;
        }
        ctx.putImageData(colored, 0, 0);

        resolve(canvas);
      }
    };

    generateGrid(0);
  });

export const generateZoneHeatMapCanvas = (
  width,
  height,
  zoneData,
  transformationMatrix,
  scale,
  zones
) =>
  new Promise((resolve) => {
    const transformedZones = (zoneData.data || []).map((zone) => ({
      ...zone,
      coordinates: ((zones[zone.zone_id] || {}).coordinates || []).map(
        (coordinate) =>
          transformMetersToPixels(coordinate, transformationMatrix, scale)
      ),
    }));

    const cGradient = gradient();
    const opacity = 0.5;
    const canvas = createCanvas(width, height);
    const ctx = canvas.getContext('2d');

    // Flip vertically (diff point of origin from ol and paper.js)
    ctx.translate(0, height);
    ctx.scale(1, -1);

    const geometries = transformedZones.filter(
      (gp) => gp.coordinates.length > 0
    );

    geometries.forEach((geometry) => {
      const alpha =
        zoneData.scale === 'logarithmic'
          ? Math.log10(
              1 +
                (9 * (geometry.weight - zoneData.min_weight)) /
                  (zoneData.max_weight - zoneData.min_weight)
            ) + 0.1
          : (geometry.weight - zoneData.min_weight) /
              (zoneData.max_weight - zoneData.min_weight) +
            0.1;
      ctx.globalAlpha = alpha > 1 ? 1 : alpha;

      geometry.coordinates.forEach((coordinate, index) => {
        if (!index) {
          ctx.beginPath();
          ctx.moveTo(coordinate[0], coordinate[1]);
        } else {
          ctx.lineTo(coordinate[0], coordinate[1]);
        }
        if (geometry.coordinates.length === index + 1) {
          ctx.closePath();
          ctx.fill();
        }
      });
    });

    const colored = ctx.getImageData(0, 0, width, height);
    for (let i = 0, len = colored.data.length, j; i < len; i += 4) {
      j = colored.data[i + 3] * 4;

      colored.data[i] = cGradient[j];
      colored.data[i + 1] = cGradient[j + 1];
      colored.data[i + 2] = cGradient[j + 2];
      colored.data[i + 3] = j === 0 ? 0 : opacity * 256;
    }
    ctx.putImageData(colored, 0, 0);

    resolve(canvas);
  });

/**
 *
 * @param   {Paper}                      paper
 * @param   {Assets}                     assets
 * @param   {number}                     height
 * @param   {SpaghettiMap}               spaghettiMapData
 * @param   {[number, number, number][]} transformationMatrix
 * @param   {number}                     scale
 * @returns {T<Path>}
 */
export const generateSpaghettiMap = (
  { Matrix, Path, Point },
  assets,
  height,
  spaghettiMapData,
  transformationMatrix,
  scale
) => {
  const paths = [];

  if (spaghettiMapData !== undefined) {
    for (let gs = 0; gs < (spaghettiMapData.data || []).length; gs += 1) {
      const asset = (spaghettiMapData.data || [])[gs] || {};
      const assetColor = (assets[asset.asset_id] || {}).color;

      (asset.coordinates || []).forEach((coords) => {
        let lastPoint;
        // Split paths into chunks of 500 points improves paperjs performance - by a lot
        for (let y = 0; y < coords.length; y += 500) {
          // Adds the last point from prev path to produce visual continuality
          const points = lastPoint ? [lastPoint] : [];

          for (let p = y; p < y + 500 && p < coords.length; p += 1) {
            if (coords[p]) {
              points.push(
                new Point(
                  transformMetersToPixels(
                    coords[p],
                    transformationMatrix,
                    scale
                  )
                )
              );
            }
          }
          lastPoint = points[points.length - 1];
          const spaghettiPath = new Path(...points);
          spaghettiPath.strokeColor =
            assetColor !== undefined ? assetColor : colors.getRandomColor();
          spaghettiPath.strokeWidth = 2;
          spaghettiPath.transform(new Matrix(1, 0, 0, -1, 0, height));
          spaghettiPath.sendToBack();
          spaghettiPath.strokeScaling = false;
          paths.push(spaghettiPath);
        }
      });
    }
  }

  return paths;
};

export const generateZoneSpaghettiMap = (
  { Color, Matrix, Path, Point },
  zones,
  height,
  spaghettiMapData,
  transformationMatrix,
  scale
) => {
  const paths = [];

  if (spaghettiMapData !== undefined) {
    for (let i = 0; i < (spaghettiMapData.data || []).length; i += 1) {
      const asset = spaghettiMapData.data[i];

      // Split paths into chunks of 500 points improves paperjs performance - by a lot
      for (let y = 0; y < asset.trajectory.length; y += 500) {
        // Adds the last point from prev path to produce visual continuality
        const points = [];
        for (
          let p = y;
          p < y + 500 &&
          p < asset.trajectory.length &&
          (!(points[points.length - 1] || {}).event ||
            (points[points.length - 1] || {}).event ===
              asset.trajectory[p].event);
          p += 1
        ) {
          points.push({
            event: asset.trajectory[p].event,
            zone_id: asset.trajectory[p].zone_id,
            point: new Point(
              transformMetersToPixels(
                [asset.trajectory[p].x, asset.trajectory[p].y],
                transformationMatrix,
                scale
              )
            ),
          });
        }

        if (points.length) {
          const zoneColor =
            (zones[points[0].zone_id] || {}).color || colors.getRandomColor();
          const color = new Color(zoneColor);
          color.alpha = points[0].event === 'entry' ? 0.8 : 0.2;

          const path = new Path(points.map((p) => p.point));
          path.strokeColor = color;
          path.strokeWidth = 2;
          path.transform(new Matrix(1, 0, 0, -1, 0, height));
          path.sendToBack();
          path.strokeScaling = false;
          paths.push(path);
        }
      }
    }
  }

  return paths;
};

/**
 *
 * @param   {Paper}                      paper
 * @param   {string}                     warehouseTz
 * @param   {Asset[]}                    assets
 * @param   {number}                     height
 * @param   {ScatterMap}                 scatterMap
 * @param   {[number, number, number][]} transformationMatrix
 * @param   {number}                     scale
 * @param   {number}                     [zoom=1]
 * @returns {T<Path>}
 */
export const generateScatterMap = (
  { Matrix, Path, Point },
  warehouseTz,
  assets,
  height,
  scatterMap,
  transformationMatrix,
  scale
) => {
  const draws = [];
  const valueVar = scatterMap.valueVar || 'weight';
  const clippingMin = scatterMap.min_weight || 0;
  const clippingMax = scatterMap.max_weight || 1;

  let values = [];
  (scatterMap.data || []).forEach((data) => {
    values = values.concat((data.points || []).map((d) => d[valueVar]));
  });

  (scatterMap.data || []).forEach((assetData, dataIndex) => {
    const asset = assets.find((a) => a.id === assetData.asset_id);
    const assetColor = (asset || {}).color || 'red';
    assetData.points.forEach((d, index) => {
      const value = d[valueVar];

      const normalizedValue =
        (d[valueVar] - scatterMap.min_weight) /
        (scatterMap.max_weight - scatterMap.min_weight);

      let radius = 60 * normalizedValue + 15;
      if (value < clippingMin) {
        radius = 15;
      } else if (value > clippingMax) {
        radius = 60;
      }

      const props = {
        asset: (asset || {}).name,
      };
      Object.keys(d.properties || {}).forEach((p) => {
        props[p] = d.properties[p];
      });

      const draw = new Path.Circle(
        new Point(
          transformMetersToPixels([d.x, d.y], transformationMatrix, scale || 1)
        ),
        radius
      );
      draw.featureInfo = {
        id: `scatter_${dataIndex}_${index}`,
        props,
        title: (asset || {}).name || 'NA',
      };
      draw.fillColor = assetColor;
      draw.fillColor.alpha = 0.5;
      draw.transform(new Matrix(1, 0, 0, -1, 0, height));
      draws.push(draw);
    });
  });

  return draws;
};
