import { create, multiplyDependencies } from 'mathjs';
import { flatten, take } from 'lodash';

const { multiply } = create({
  multiplyDependencies,
});

/**
 * @description Calculates the final coordinates from the initial coordinates and matrix
 * Formula: F = T * I
 * https://en.wikipedia.org/wiki/Affine_transformation#Augmented_matrix
 * coordinates format:
 * x x x
 * y y y
 * 1 1 1
 * @param   {number[][]}             coordinates
 * @param   {TransformationMatrix2D} transformationMatrix
 * @returns {number[][]}
 */
export const affineTransform = (coordinates, transformationMatrix) => {
  return multiply(transformationMatrix, coordinates);
};

/**
 * @description Transform coordinates taking into account the transformation matrix (in meters)
 * Formula: F = T * I
 * @param   {[number, number]}       coordinates          in meters
 * @param   {TransformationMatrix2D} transformationMatrix in meters
 * @returns {[number, number]}
 */
export const transformMeters = (coordinates, transformationMatrix) => {
  const coordsWithZ = coordinates.concat(1);

  // Transforms a Coordinate to a single column matrix.
  // Example: [2, 3, 1] => [[2], [3], [1]]
  const singleColumnCoords = coordsWithZ.map((v) => [v]);

  const transformedCoords = affineTransform(
    singleColumnCoords,
    transformationMatrix
  );

  const flatCoordinates = take(flatten(transformedCoords), 2);

  return flatCoordinates;
};

/**
 * @param   {[number, number]} coordinate
 * @param   {number}           scale Pixels/meters
 * @returns {[number, number]}
 */
export const convertMetersToPixels = (coordinate, scale) => {
  return coordinate.map((c) => c * scale);
};

/**
 * @description Transform coordinates in meters to pixels taking into account the scale (pixels/meters)
 * and the transformation matrix (in meters)
 * Formula: F = T * I
 * @param   {[number, number]}       coordinates          in meters
 * @param   {TransformationMatrix2D} transformationMatrix in meters
 * @param   {number}                 scale                in pixels/meters
 * @returns {[number, number]}
 */
export const transformMetersToPixels = (
  coordinates,
  transformationMatrix,
  scale
) => {
  const transformedCoordinates = transformMeters(
    coordinates,
    transformationMatrix
  );

  return convertMetersToPixels(transformedCoordinates, scale);
};
