export const convertToFinalUnits = (accountUnits, type, value) => {
  switch (type) {
    case 'speed':
      if (accountUnits === 'si') {
        // Convert from m/s to km/h
        return (value * 18) / 5;
      }
      // Convert from m/s to mph
      return value / 0.44704;

    case 'distance':
      if (accountUnits === 'si') {
        // No need for conversion, units already in meters
        return value;
      }
      // Convert from m to ft
      return value / 0.3048;

    case 'duration':
      return value / 60 / 60;
    default:
      return value;
  }
};

export const PERMISSIONS = [
  'settings',
  'add_warehouse',
  'edit_warehouse',
  'del_warehouse',
  'add_sensor',
  'add_sensor_group',
  'deliver_sensor_info',
  'add_rule',
  'edit_rule',
  'del_rule',
  'add_asset',
  'edit_asset',
  'del_asset',
  'diagnostics',
];
