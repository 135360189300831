export const timeZone =
  Intl && Intl.DateTimeFormat && Intl.DateTimeFormat().resolvedOptions
    ? (Intl.DateTimeFormat().resolvedOptions() || {}).timeZone ||
      'Europe/Lisbon'
    : 'Europe/Lisbon';

/**
 * Get time zone offset from Date in a given time zone (diff than default)
 * @param {Date}   date Date.
 * @param {string} tz   Timezone used for the provided time (to which the offset will be added).
 */
const getTimezoneOffset = (date, tz) => {
  const a = date.toLocaleString('ja', { timeZone: tz }).split(/[/\s:]/);
  a[1] -= 1;
  const t1 = Date.UTC.apply(null, a);
  const t2 = new Date(date).setMilliseconds(0);
  return (t2 - t1) / 60 / 1000;
};

/**
 * Extends JS Date class and adds format method
 * constructor is also capable of adding sourceTz offsets to provided ts
 * @param {string}                                    targetTz   Timezone used to show format() date.
 * @param {number | string | Date | undefined | null} [d]        Time.
 * @param {string}                                    [sourceTz] Timezone used for the provided time (to which the offset will be added).
 */
class LogiDate extends Date {
  constructor(targetTz, d, sourceTz) {
    const source =
      d === undefined ||
      d === null ||
      typeof d === 'number' ||
      typeof d === 'string'
        ? new Date(d === undefined || d === null ? new Date() : new Date(d))
        : d;

    if (sourceTz) {
      const sourceOffset = getTimezoneOffset(source, sourceTz);
      source.setTime(
        source.getTime() -
          source.getTimezoneOffset() * 60 * 1000 +
          sourceOffset * 60 * 1000
      );
    }

    super(source.getTime());

    this.timeZone = targetTz;
  }

  getDateInUTC() {
    const targetOffset = getTimezoneOffset(
      new Date(this.getTime() - this.getTimezoneOffset() * 60 * 1000),
      this.timeZone
    );
    return new Date(this.getTime() - targetOffset * 60 * 1000);
  }

  getDateInCurrentZone() {
    const targetOffset = getTimezoneOffset(
      new Date(this.getTime() - this.getTimezoneOffset() * 60 * 1000),
      this.timeZone
    );
    return new Date(
      this.getTime() -
        targetOffset * 60 * 1000 +
        this.getTimezoneOffset() * 60 * 1000
    );
  }

  format(stringFormat = 'yyyy-MM-dd HH:mm') {
    const utcDate = this.getDateInUTC();

    return stringFormat
      .replace('yyyy', utcDate.getUTCFullYear().toString())
      .replace('MM', `0${utcDate.getUTCMonth() + 1}`.slice(-2))
      .replace('dd', `0${utcDate.getUTCDate()}`.slice(-2))
      .replace('HH', `0${utcDate.getUTCHours()}`.slice(-2))
      .replace('mm', `0${utcDate.getUTCMinutes()}`.slice(-2))
      .replace('ss', `0${utcDate.getUTCSeconds()}`.slice(-2));
  }
}

export default LogiDate;
