const colors = [
  '#1f77b4', // muted blue
  '#ff7f0e', // safety orange
  '#2ca02c', // cooked asparagus green
  '#d62728', // brick red
  '#9467bd', // muted purple
  '#8c564b', // chestnut brown
  '#e377c2', // raspberry yogurt pink
  '#7f7f7f', // middle gray
  '#bcbd22', // curry yellow-green
  '#17becf', // blue-teal
];

class RandomColor {
  constructor() {
    this.colorIndex = -1;
  }

  getRandomColor() {
    this.colorIndex = this.colorIndex + 1 < 10 ? this.colorIndex + 1 : 0;

    return colors[this.colorIndex];
  }

  resetRandomColor() {
    this.colorIndex = -1;
  }
}

export default new RandomColor();
